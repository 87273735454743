import React from "react"
// import { Link } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Globe, Clock, ExternalLink } from 'react-feather'
import { Tabs, Tab } from "react-bootstrap"
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import WorldMap from "../../components/worldmap"


export default class Finland extends React.Component {
  constructor(props){
      super(props);

      this.state = {
          showEcosystemsLink: false,
          showNextLink: false,
          showPrevLink: false
      }

      this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event){
      // Esc key press
      if(event.keyCode === 27) {
          this.setState({
              showEcosystemsLink: true
          });

          setTimeout(() => {
              let linkRef = document.getElementById("esc-action");
              linkRef.click();
          }, 50)
      }

      // Left Arrow key press
      if(event.keyCode === 37) {
          this.setState({
              showPrevLink: true
          });

          setTimeout(() => {
              let prevLinkRef = document.getElementById("prev-action");
              prevLinkRef.click();
          }, 50)
      }

      // Right Arrow key press
      if(event.keyCode === 39) {
          this.setState({
              showNextLink: true
          });

          setTimeout(() => {
              let nextLinkRef = document.getElementById("next-action");
              nextLinkRef.click();
          }, 50)
      }
  }
  componentDidMount(){
      document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount(){
      document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
      return (
  <Layout>
            {
            this.state.showEcosystemsLink &&
            <AniLink id="esc-action" fade to="/startup-visas" duration={0.4}></AniLink >
        }
        {
            this.state.showPrevLink &&
            <AniLink id="prev-action" fade to="/startup-visas/lithuania" duration={0.4}></AniLink >
        }
        {
            this.state.showNextLink &&
            <AniLink id="next-action" fade to="/startup-visas/denmark" duration={0.4}></AniLink >
        }
    <SEO title="Finland's Startup Visa" />
    <div id="top-part">
    <div id="back">
        <AniLink fade to="/startup-visas" duration={0.4}>
          <em>{'<-'}</em> All Startup Visas
        </AniLink>
      </div>
    <div id="contains-map">
      <div id="finland_map_container">
      <WorldMap />
      </div>
      </div>
      <div id="left-col">
        <div id="left-col-content">
          <div className="page-icon-container" style={{fontSize:'48px'}}>
          <span role="img" aria-label="Finland">🇫🇮</span>

          </div>
          <h1 id="country-header">Finland's Startup Visa</h1>

        </div>
      </div>
      <div id="right-col">
      
        {/* <AniLink fade to="/interviews/finland" className="colled">
          <div className="long-container">
            
            <div className="item-header">
            <Coffee className="slack-icon"/> 
            Interview with Program Manager
              <div className="item-arrow">
                →
              </div>
            </div>
            <div className="item-description">
            </div>

          </div>
        </AniLink> */}
        <OutboundLink className="colled" href="https://www.businessfinland.fi/en/do-business-with-finland/startup-in-finland/startup-permit/" target="_blank">
          <div className="external-container">
            <div className="item-header">
              <div className="long-icon-container">
                <Globe className="slack-icon"/> 
              </div>
            Official Website
              <div className="item-arrow">
              <ExternalLink className="slack-icon"/> 
              </div>
            </div>
            <div className="item-description">
            </div>
          </div>
        </OutboundLink>
        <OutboundLink href="https://secure.businessfinland.fi/startup-permit" target="_blank">
          <div className="external-container">
            <div className="item-header">
              <div className="long-icon-container">
                <Globe className="slack-icon"/> 
              </div>
              Apply Now
              <div className="item-arrow">
              <ExternalLink className="slack-icon"/> 
              </div>
            </div>
            <div className="item-description">
            </div>
          </div>
        </OutboundLink>
      
    
      </div>
    </div>
    <div className="content-container">

    <Tabs defaultActiveKey="visa-description" id="country-tabs">
    <Tab eventKey="visa-description" title="Visa Information" id="visa-information-tab">
    <p>The Finnish Startup Permit makes it possible for international growth entrepreneurs to build a startup company in Finland and to become part of Finland's vibrant startup ecosystem. The permit is meant for innovative startup founders coming from countries outside the European Union.</p>

    <p>Before sending your Startup Permit application to the Finnish Immigration Service, you must get a favorable assessment from Business Finland. Business Finland evaluates whether your business model, team and resources show potential for rapid international growth. After the assessment you will receive an Eligibility Statement to be attached to the Startup Permit application.</p> 

<p>The permit can initially be issued for max. two years, after which it can be renewed. The permit does not involve investments or financial support.</p>

      <table className="country-table">
        <tr>
          <td className="table-label">Degree Required</td>
          <td className="table-value">Not Required</td>
        </tr>
        <tr>
          <td className="table-label">Visa Duration</td>
          <td className="table-value">24 months</td>
        </tr>
          <tr>
          <td className="table-label">Bootstrappers</td>
          <td className="table-value">Yes</td>
        </tr>
          <tr>
          <td className="table-label">Visa Fees</td>
          <td className="table-value">$390</td>
        </tr>
          <tr>
          <td className="table-label">Personal Funds</td>
          <td className="table-value">$26,760</td>
        </tr>
          <tr>
          <td className="table-label">Minimum Investment</td>
          <td className="table-value">$0</td>
        </tr>
          <tr>
          <td className="table-label">Processing Time</td>
          <td className="table-value">90 days</td>
        </tr>
      </table>
    </Tab>
    <Tab eventKey="reviews" title="Founder Reviews" id="visa-information-tab">
      <div className="jobs-container">
          <div className="review-container">
              <div className="job-description">
                <p>I’ve managed to successfully immigrate to Finland, incorporate, and start my business. It was a really smooth process that didn’t take much effort besides getting the paperwork in order. Really recommended, it’s really a great place to be and build the future.</p>
              </div>
              <div className="interview-details">
                <div className="left-details">
                <div className="job-company">
                 Ziyad Basheer
                </div>
                <div className="job-tags">
                  <div className="job-tag">
                    <div className="job-tag-text">
                      Founder
                    </div>
                  </div>
                  </div>
                </div>
                <div className="right-details">
                  <div className="job-item">
                    <div>
                      <Clock className="job-icon"/>
                    </div>
                    Processed in 45 days
                  </div>
                </div>
              </div>
          </div>
      </div>
    </Tab>
  </Tabs>
    </div>
  </Layout>
) }
 }